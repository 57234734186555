<template>
  <div class="no-content">
    <div class="bubble animated pulse infinite">
      <slot></slot>
    </div>
    <b-row
      class="no-content-row"
      align-v="end"
      no-gutters>
      <b-col class="text-center" cols="12" lg="5" xl="4">
        <img
          class="img-fluid char princess"
          src="../../assets/images/home/section-2-landing-page-princess.png"
          alt="Princess Panda"
          v-if="char === 'princess'"
        />
        <img
          class="img-fluid char"
          src="../../assets/images/home/section-1-landing-page-pj-full.png"
          alt="PJ Panda"
          v-if="char === 'pj_panda'"
        />
        <img
          class="img-fluid char"
          src="../../assets/images/home/leo.png"
          alt="Leo"
          v-if="char === 'leo'"
        />
      </b-col>
      <b-col class="chars-col" cols="12" lg="7" xl="8">
        <img
          class="img-fluid h3-characters"
          src="../../assets/images/home/section-3-landing-page-h3-squad.png"
          alt="characters"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        characters : [
          'pj_panda',
          'princess',
          'leo',
        ],
      }
    },
    computed : {
      char() {
        return this.characters[Math.floor(Math.random()*
          this.characters.length)];
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  .no-content {
    margin-top: -9rem;
  }

  .chars-col {
    text-align: right;
  }
  .h3-characters {
    margin-top: 15rem;
    max-width: 30rem;
  }
  .bubble {
    position: relative;
    margin: 0 auto;
    padding: 1.5rem;
    top: 14rem;
    left: 6rem;
    z-index: 1;
    background-color: #fff;
    border: 10px solid #4fb8e9;
    border-radius: 2em;
    width: fit-content;

    &:before {
      content: "";
      position: absolute;
      top: 20%;
      left: -60px;
      width: 0;
      border-top: 30px solid transparent;
      border-left: 30px solid transparent;
      border-right: 30px solid #4fb8e9;
      border-bottom: 30px solid transparent;
    }
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    .no-content {
      margin-top: -5rem;
    }
    // .no-content-row {
    //   border: 0.5em solid #4fb8e9;
    // }
    .chars-col {
      text-align: center;
    }
    .char {
      display: none;
    }
    .h3-characters {
      margin-top: 10rem;
      width: 15rem;
    }
    .bubble {
      padding: 1rem;
      top: 9rem;
      left: 0;
      width: 85%;
      border: 5px solid #4fb8e9;
      text-align: center;
      
      &:before {
        display: none;
      }
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .no-content {
      margin-top: -6rem;
    }
    // .no-content-row {
    //   border: 0.5em solid #4fb8e9;
    // }
    .chars-col {
      text-align: center;
    }
    .char {
      display: none;
    }
    .h3-characters {
      width: 20rem;
    }
    .bubble {
      padding: 1rem;
      top: 10rem;
      left: 0;
      width: 85%;
      border: 5px solid #4fb8e9;
      text-align: center;
      
      &:before {
        display: none;
      }
    }
  }
  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {}
  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    .no-content {
      margin-top: -7rem;
    }
    .chars-col {
      text-align: right;
    }
    .char {
      display: inline;
      width: 15rem;
    }
    .h3-characters {
      margin-top: 10rem;
      width: 18rem;
    }
    .bubble {
      width: 50%;
      left: 6rem;
      border: 7px solid #4fb8e9;

      &:before {
        display: inline;
        top: 25%;
        left: -2.9rem;
        border-top: 20px solid transparent;
        border-left: 20px solid transparent;
        border-right: 20px solid #4fb8e9;
        border-bottom: 20px solid transparent;
      }
    }
  }
  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    .no-content {
      margin-top: -6rem;
    }
  }
  @media only screen and (min-width: $special) {}
  @media only screen and (min-width: $xl2-min) {
    .no-content {
      margin-top: -7.6rem;
    }
    .char {
      width: 80%;
    }
    .princess {
      width: 95%;
    }
    .h3-characters {
      margin-top: 15rem;
      width: 25rem;
    }
  }
  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {}
</style>